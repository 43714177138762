import { IdbStore } from "src/app/lite/local-db/idb.store";
import type { QueueResource } from "src/app/lite/local-db/resources/queue/queue.resource";
import type {
   QueueableSchema,
   ToJob,
} from "src/app/lite/local-db/resources/queue/queue.resource.types";
import { z } from "zod";

export class QueueStore<JobDataSchema extends QueueableSchema> extends IdbStore {
   private readonly schema: JobDataSchema;

   public constructor({ name, schema }: QueueResource<JobDataSchema>) {
      super({ store: name, database: "litePersist" });
      this.schema = schema;
   }

   public async getFront(
      transaction: IDBTransaction,
   ): Promise<ToJob<JobDataSchema> | null> {
      return this.getNextJob(transaction);
   }

   public async dequeueJob(transaction: IDBTransaction): Promise<void> {
      const nextJob = await this.getNextJob(transaction);
      if (nextJob === null) return;
      return this.deleteOne({ key: nextJob.jobID, transaction });
   }

   public async countJobs(transaction: IDBTransaction): Promise<number> {
      return this.count(transaction);
   }

   public async queueJob(
      jobData: z.infer<JobDataSchema>,
      transaction: IDBTransaction,
   ): Promise<number> {
      return this.addOne({
         value: jobData,
         objectSchema: this.schema,
         keySchema: z.number(),
         transaction,
         skipValidation: false,
      });
   }

   public async getNextJob(
      transaction: IDBTransaction,
   ): Promise<ToJob<JobDataSchema> | null> {
      const request = this.getStore(transaction).openCursor();
      const result = await this.first({
         request,
         keySchema: z.number(),
         valueSchema: this.schema,
      });

      return result === null ? null : this.formatJob(result);
   }

   private formatJob(firstResult: {
      key: number;
      value: z.infer<JobDataSchema>;
   }): ToJob<JobDataSchema> {
      return {
         jobID: firstResult.key,
         ...firstResult.value,
      };
   }

   public async clearJobs(transaction: IDBTransaction): Promise<void> {
      await this.clearStore(transaction);
   }
}
