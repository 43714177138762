import type { Observable } from "rxjs";
import { EMPTY } from "rxjs";
import { idbNameToDb } from "src/app/lite/local-db/idb";
import type { IDBDatabaseName } from "src/app/lite/local-db/idb.types";
import type {
   QueueResourceConfig,
   QueueableSchema,
} from "src/app/lite/local-db/resources/queue/queue.resource.types";
import type { Resource } from "src/app/lite/local-db/resources/resource.types";

export class QueueResource<JobDataSchema extends QueueableSchema = any>
   implements Resource<never>
{
   public readonly databaseName: IDBDatabaseName;
   public readonly name: QueueResourceConfig<JobDataSchema>["name"];
   public readonly schema: JobDataSchema;
   public readonly batchSize = 0;

   public constructor(config: QueueResourceConfig<JobDataSchema>) {
      this.databaseName = "litePersist";
      this.name = config.name;
      this.schema = config.validation;
   }

   public createIdbStore(idb: IDBDatabase): IDBObjectStore {
      return idb.createObjectStore(this.name, {
         keyPath: "jobID",
         autoIncrement: true,
      });
   }

   // eslint-disable-next-line typescript/no-empty-function -- Queue stores do not create indexes
   public createIdbIndexes(): void {}

   public fetch$(): Observable<never> {
      return EMPTY;
   }

   public async createTransaction(mode: IDBTransactionMode): Promise<IDBTransaction> {
      const db = await idbNameToDb[this.databaseName].connect();
      return db.transaction(this.name, mode);
   }

   // eslint-disable-next-line typescript/no-empty-function -- Queue stores do not initialize data
   public async store(): Promise<void> {}

   // eslint-disable-next-line typescript/no-empty-function -- Queue stores do not initialize data
   public endStoring(): void {}
}
