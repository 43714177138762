import type { Observable } from "rxjs";
import { from, take } from "rxjs";
import type { QueueResource } from "src/app/lite/local-db/resources/queue/queue.resource";
import type {
   QueueableSchema,
   ToJob,
} from "src/app/lite/local-db/resources/queue/queue.resource.types";
import { QueueStore } from "src/app/lite/local-db/resources/queue/queue.store";
import { StorageService } from "src/app/lite/local-db/storage.service";
import type { z } from "zod";

export class QueueManager<JobDataSchema extends QueueableSchema> extends StorageService<
   QueueStore<JobDataSchema>
> {
   public constructor(resource: QueueResource<JobDataSchema>) {
      super(new QueueStore(resource));
   }

   public get getFront$(): Observable<ToJob<JobDataSchema> | null> {
      return this.read$((transaction) => from(this.store.getFront(transaction))).pipe(
         take(1),
      );
   }

   public get count$(): Observable<number> {
      return this.read$((transaction) => from(this.store.countJobs(transaction))).pipe(
         take(1),
      );
   }

   public dequeue$(): Observable<void> {
      return from(
         this.write(async (transaction) => this.store.dequeueJob(transaction)),
      ).pipe(take(1));
   }

   public queue$(jobData: z.infer<JobDataSchema>): Observable<number> {
      return from(
         this.write(async (transaction) => this.store.queueJob(jobData, transaction)),
      ).pipe(take(1));
   }

   public clearQueue(): void {
      this.write(async (transaction) => {
         await this.store.clearJobs(transaction);
      });
   }
}
